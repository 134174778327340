<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <input type="file" class="form form-control" @change="onChange" />
            </div>
            <div class="col">
              <select
                name="sheet"
                v-model="sheetSelection"
                id=""
                class="form form-control"
              >
                <option v-for="sheet in sheetList" :key="sheet" :value="sheet">
                  {{ sheet }}
                </option>
              </select>
            </div>
            <div class="col-auto text-right">
              <button class="btn btn-primary" @click="handleChangeSheet">
                Load
              </button>

              <!-- <button class="btn btn-primary" @click="doUpload">Upload</button> -->
            </div>
          </div>
          <hr />
          <vue-excel-editor v-if="jsondata" v-model="jsondata" filter-row />
          <pre>
            {{ logMessage }}
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { read, utils } from "xlsx";
import Service from "../services/base.service";

export default {
  components: {
    // VueExcelEditor,
  },
  data() {
    return {
      file: null,
      fileBinary: null,
      jsondata: null,
      logMessage: [],
    };
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = read(bstr, { type: "binary" });
          this.fileBinary = wb;
          this.sheetList = wb.SheetNames;
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = utils.sheet_to_json(ws, { header: 1 });
          this.jsondata = data;
          const headerData = data[0].map((r) => {
            return r
              .toLowerCase()
              .trim()
              .replace(".", "")
              .replace("/", "")
              .replace(" ", "_")
              .replace("  ", "_")
              .replace("   ", "_");
          });
          console.log("headerData", headerData);
        };
        reader.readAsBinaryString(this.file);
      }
      // console.log(event);
    },
    handleChangeSheet() {
      const load = this.fileBinary.Sheets[this.sheetSelection];
      const data = utils.sheet_to_json(load, { header: 1 });
      this.jsondata = data;
    },
    async createPerson(row) {
      const personService = new Service("person");
      const cardApplicationService = new Service("card-application");

      const param = {
        nip_nrp: row[5],
        nama_lengkap: row[4],
        tinggi: row[12] ? parseInt(row[12].replace(/[^.\d]/g, "")) : 0,
        berat: row[13] ? parseInt(row[13].replace(/[^.\d]/g, "")) : 0,
        rambut: row[14] ?? null,
        mata: row[15] ?? null,
        gol_darah: row[11] ? row[11].trim() : null,
        tempat_lahir: row[19] ?? null,
        tanggal_lahir: row[20] ?? null,
        agama: row[21] ?? null,
        alamat: row[17] ?? null,
        kontak_darurat: row[16] ?? null,
        nama_pasangan: row[18] ?? null,
        nama_ortu: row[22] ?? null,
        tempat_kawin: row[23] ?? null,
        no_surat_kawin: row[24] ?? null,
        dimension_name: row[10] ?? null,
        position_name: row[8] ?? null,
        rank_name: row[6] ?? null,
        unity_name: row[9] ?? null,
        honors: row[25] ? [row[25]] : null,
      };
      try {
        const person = await personService.createData(param);
        if (person.data) {
          this.logMessage.push(
            `create person: #${person.data.id} => ${person.data.nama_lengkap}`
          );

          // KTA NUMBER
          if (person && row[3]) {
            const cardApplicationParam = {
              kta_number: row[3],
              person_id: person.data.id,
              card_type_name: row[2],
            };

            const cardApplication = await cardApplicationService.createData(
              cardApplicationParam
            );

            this.logMessage.push(
              `create card application person: #${cardApplication.data.id} #${person.data.id} => ${cardApplication.data.kta_number}`
            );

            if (cardApplication && row[1]) {
              const cardApprovalService = new Service("approval/approve");
              const cardService = new Service("card");
              // APPROVE  CARD APPLICATION
              // const cardApproval = await cardApprovalService.createData({
              //   cardApplicationId: cardApplication.data.id,
              //   status: "TTE",
              // });
              // console.log(cardApproval);
              // const card = await cardService.createData({
              //   cardApplicationId: cardApplication.data.id,
              //   serial_number: row[1],
              // });
              // console.log(card);
              const mergeRequest = [
                cardApprovalService.createData({
                  cardApplicationId: cardApplication.data.id,
                  status: "TTE",
                }),
                cardService.createData({
                  cardApplicationId: cardApplication.data.id,
                  serial_number: row[1],
                }),
              ];
              const [approval, card] = await Promise.all(mergeRequest);
              console.log(approval, card);
              this.logMessage.push(
                `approve card application: #${approval.data.id} #${person.data.id}`
              );
              this.logMessage.push(
                `create card : #${card.data.id} #${card.data.serial_number}`
              );
            }
          }
        } else {
          this.logMessage.push(
            `create person: #${person.message} => ${param.nip_nrp}`
          );
        }
      } catch (error) {
        this.logMessage.push(error);
        // console.log(error);
      }
    },
    doUpload() {
      const rawData = toRaw(this.jsondata);
      // this.createPerson(rawData[1]);
      rawData.forEach((row, index) => {
        if (index >= 1) {
          this.createPerson(row);
        }
      });
    },
  },
};
</script>
